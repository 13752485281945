import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
// import { posts } from "../../../_mock/blog";
import MobFeedCard from "../news-card";
import ArticleService from "src/services/Article.service";
import DiscussionModal from "../Discussion";
import { useParams, useLocation } from "react-router-dom";
import SkeletonView from "./feed-skeleton-view";
import EmptyView from "./empty-feed-view";
import ShareView from "../share-view";
import useQueryParams from "src/hooks/useQueryParams";
import Scrollbar from "src/components/scrollbar";
import useAuth from "src/hooks/useAuth";

// import { posts } from 'src/_mock/blog';
// ----------------------------------------------------------------------

export default function FeedView() {
  const [{ owners }] = useQueryParams();
  const { user } = useAuth();
  const scrollRef = useRef(null);

  const { hash } = useLocation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [articles, setArticles] = useState([]);
  let { articleId } = useParams();
  const [articleid, setArticleid] = useState("");
  const [shareurl, setShareurl] = useState("");
  const [isSharable, setIsSharable] = useState(false);

  const handleScroll = (e) => {
    const scrollContent = e.target;
    if (!scrollContent) return;
    const scrollTop = scrollContent.scrollTop;
    const scrollHeight = scrollContent.scrollHeight;
    const clientHeight = scrollContent.clientHeight;

    // Check if the scroll is at the bottom
    if (scrollTop + clientHeight >= scrollHeight - 2 * window.innerHeight) {
      if (isLoading) return;
      fetchArticles({ more: true });
    }
  };

  const initPageAfterLoad = useCallback(() => {
    setIsLoading(false);
    if (hash) {
      setTimeout(() => {
        const elementId = hash.substring(
          hash.indexOf("#"),
          hash.indexOf("?") ?? hash.length
        );

        document.querySelector(elementId)?.scrollIntoView();
      }, 50);
    }
  }, [hash, setIsLoading]);

  const fetchArticles = useCallback(
    async ({ more = false } = {}) => {
      try {
        if (isLoading) return;
        setIsLoading(true);
        const fetchedArticles = await ArticleService.fetchArticles({
          owners,
          id: articleId || "",
          userId: "64b6f1d254e7a15f3c1d4b7a",
        });
        if (more !== true) setArticles(fetchedArticles.articles);
        setArticles((prevArticles) => [
          ...prevArticles,
          ...fetchedArticles.articles,
        ]);
      } catch (error) {
        console.log("Error in fetching articles in feed-view page: ", error);
      } finally {
        initPageAfterLoad();
      }
    },
    [isLoading, owners, articleId, initPageAfterLoad]
  );

  useLayoutEffect(() => {
    fetchArticles();
  }, [articleId, owners, hash]);

  return (
    <>
      <DiscussionModal
        open={open}
        setOpen={setOpen}
        articleid={articleid}
        owner={user?._id}
      />
      <ShareView
        isOpen={isSharable}
        setIsOpen={setIsSharable}
        shareurl={shareurl}
      />
      <Container
        sx={{
          ...(isMobile ? { pl: 0, pr: 0 } : {}),
          height: "100%",
        }}
      >
        {!isMobile && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Typography variant="h4">Feed</Typography>
          </Stack>
        )}
        <Scrollbar
          ref={scrollRef}
          onScrollCapture={handleScroll}
          sx={{
            overflowY: "auto",
            height: "100%",
            ...(!isMobile && { pr: 2 }),
            "& .simplebar-content-wrapper": {
              scrollSnapType: "y mandatory",
            },
          }}
        >
          {Boolean(!isLoading && !articles.length) && <EmptyView />}
          {Boolean(articles.length > 0) && (
            <>
              {articles.map((article) => (
                <MobFeedCard
                  key={article.id}
                  article={article}
                  setOpen={setOpen}
                  setIsSharable={setIsSharable}
                  setShareurl={setShareurl}
                  setArticleid={setArticleid}
                />
              ))}
            </>
          )}
          {isLoading && <SkeletonView />}
        </Scrollbar>
      </Container>
    </>
  );
}
