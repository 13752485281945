import {
  ARTICLE_FILTER_URL,
  ARTICLE_URL,
  CREATE_ARTICLE_URL,
  INTERACTION_URL,
} from "src/config/api.config";
import { doGet, doPost } from "src/utils/apiCallers";
import { fDate } from "src/utils/format-time";

export default class ArticleService {
  static constructUrl(query = {}) {
    return query?.limit ? `${ARTICLE_FILTER_URL}` : ARTICLE_URL;
  }

  // Function to generate random numbers within a range
  static generateRandomNumber(max = 99999) {
    return Math.floor(Math.random() * max);
  }

  // Function to map API response data into the desired format
  static mapPostsData(data) {
    return data.map((post, index) => ({
      id: post._id,
      cover: post.coverImg,
      title: post.title,
      body: post.body,
      createdAt: fDate(post.createdAt), // Use the format time utility
      view: post.views,
      comment: this.generateRandomNumber(), // Generate random comment count
      share: this.generateRandomNumber(), // Generate random share count
      like: post.likes,
      owner: post.owner,
      isLiked: post.isLiked,
      isDisliked: post.isDisliked,
      link: post.link,
    }));
  }

  // Main function to fetch posts
  static async fetchArticles(query) {
    try {
      const url = this.constructUrl(query);
      const result = await doGet(url, query);
      const { articles = [], pagination = {} } = result.data;
      const data = Array.isArray(result.data) ? result.data : articles;

      if (!data.length) {
        console.warn("No data received from the API");
        return {};
      }

      return {
        articles: this.mapPostsData(data),
        totalArticles: pagination.totalArticles || 0,
        totalPages: pagination.totalPages || 1,
      };
    } catch (error) {
      console.error("Error fetching articles catch:", error);
      return { articles: [], totalArticles: 0, totalPages: 1 };
    }
  }

  static async createArticle(articleData) {
    const { title, body, coverImg, category, owner, topic } = articleData;
    try {
      if (!title || !body || !coverImg || !category || !owner) {
        throw new Error("All fields are required to create an article");
      }
      const payload = {
        title,
        body,
        coverImg,
        owner,
        category,
        topic,
      };
      const { success, message, data } = await doPost(
        CREATE_ARTICLE_URL,
        payload,
        null,
        { "Content-Type": "application/json" },
        null
      );
      return { success, message, data };
    } catch (error) {
      console.error("Error in posting article:", error);
      return {
        success: false,
        message: error.message || "Failed to create article",
        data: {},
      };
    }
  }

  static async LikeArticle(user) {
    try {
      await doPost(
        `${INTERACTION_URL}/${user}/like`,
        { user: user },
        null,
        { "Content-Type": "application/json" },
        null
      );
      return 1;
    } catch (error) {
      console.error("Error in like:", error);
      return -1;
    }
  }
  static async DislikeArticle(user) {
    try {
      await doPost(
        `${INTERACTION_URL}/${user}/dislike`,
        { user: user },
        null,
        { "Content-Type": "application/json" },
        null
      );
      return 1;
    } catch (error) {
      console.error("Error in dislike:", error);
      return -1;
    }
  }
}
