// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBGUzrQUqB_1KTLB1NUWv1iH1tjJ8hgbdo",
  authDomain: "inkpod-app.firebaseapp.com",
  projectId: "inkpod-app",
  storageBucket: "inkpod-app.firebasestorage.app",
  messagingSenderId: "266323292441",
  appId: "1:266323292441:web:c282cad40634064abe1588",
  measurementId: "G-F3ZDF2YRRD",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };
