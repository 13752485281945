import React from "react";
import { Helmet } from "react-helmet-async";
import VerifyOTPView from "src/sections/forgot-password/verify-otp";

const VerifyOTP = () => {
  return (
    <>
      <Helmet>
        <title> Riple - Verify OTP </title>
      </Helmet>
      <VerifyOTPView />
    </>
  );
};

export default VerifyOTP;
