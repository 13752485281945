import React from "react";
import { Helmet } from "react-helmet-async";
import SignupView from "src/sections/signup/signup-view";

const SignupPage = () => {
  return (
    <>
      <Helmet>
        <title> Riple - Sign Up </title>
      </Helmet>
      <SignupView />
    </>
  );
};

export default SignupPage;
