// LOCAL
// const BASE_URL = "http://localhost:9090/local";

// DEV
// const BASE_URL = "https://api2-dev.riple.org";

// PROD
const BASE_URL = "https://api2.riple.org/v1";

export const AUTH_URL = `${BASE_URL}/auth`;
export const SIGNUP_URL = `${AUTH_URL}/signup`;
export const LOGIN_URL = `${AUTH_URL}/login`;
export const LOGOUT_URL = `${AUTH_URL}/logout`;
export const GET_LOGGED_IN_URL = `${AUTH_URL}/logged-in-user`;
export const REFRESH_TOKEN_URL = `${AUTH_URL}/refresh-token`;

export const ADMIN_ARTICLE_URL = `${BASE_URL}/articles/filter`;
export const ROLE_URL = `${BASE_URL}/articles/filter`;
export const ARTICLE_URL = `${BASE_URL}/articles`;
export const ARTICLE_FILTER_URL = `${BASE_URL}/articles/filter`;
export const COMMENT_URL = `${BASE_URL}/comments`;
export const GOOGLE_AUTH_URL = `${AUTH_URL}/google`;
export const CREATE_ARTICLE_URL = `${BASE_URL}/articles`;
export const INTERACTION_URL = `${BASE_URL}/interactions/create`;
export const USER_URL = `${BASE_URL}/user/`;
export const SEND_OTP_URL = `${AUTH_URL}/send-otp`;
// FORGET_PASSWORD_URL,
export const RESET_PASSWORD_URL = `${AUTH_URL}/reset-password`;
// CHANGE_PASSWORD_URL,
