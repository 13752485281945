// /* eslint-disable perfectionist/sort-imports */
import ThemeProvider from "./theme/index";
import "./global.css";

import { useScrollToTop } from "./hooks/use-scroll-to-top";

import Router from "./routes/sections";
import { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { analytics } from "./config/firebase.config";
import { logEvent } from "firebase/analytics";

// ----------------------------------------------------------------------

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_path: "WEB_APP" + location.pathname,
    });
  }, [location]);

  return null;
};

// const handleButtonClick = () => {
//   logEvent(analytics, "button_click", { button_name: "Sign Up" });
// };

export default function App() {
  useScrollToTop();

  return (
    <ThemeProvider>
      <TrackPageView />
      <Toaster position="top-center" reverseOrder={false} />
      <Router />
    </ThemeProvider>
  );
}
