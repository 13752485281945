import React, { useCallback, useState } from "react";
import {
  IconButton,
  Stack,
  TextField,
  Button,
  Typography,
  Card,
  Box,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";
import { alpha, useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import { bgGradient } from "../../theme/css";
import Logo from "../../components/logo";
import Iconify from "../../components/iconify";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import AuthService from "src/services/Auth.service";
import toast from "react-hot-toast";

const SignupView = () => {
  const theme = useTheme();
  const { login } = useAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const onSubmit = useCallback(
    async (formData, setSubmitting, reset) => {
      try {
        setSubmitting(true);
        const res = await AuthService.signup(formData);
        if (res.success) {
          toast.success(res.message);
          await login(formData.email, formData.password);
        } else {
          toast.error(res.message);
        }
        reset();
      } catch (error) {
        console.error("Error Signing up:", error);
        toast.error("Failed to Sign up. Please try again.");
      } finally {
        setSubmitting(false);
      }
    },
    [AuthService.signup, login]
  );
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("This field is required"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      onSubmit(values, setSubmitting, resetForm);
    },
  });
  const renderForm = (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={3}>
        <TextField
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          name="name"
          label="Name"
          helperText={formik.touched.name && formik.errors.name}
        />
        <TextField
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          name="email"
          label="Email address"
          helperText={formik.touched.email && formik.errors.email}
        />

        <TextField
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
          helperText={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
          name="confirmPassword"
          label="Confirm Password"
          type={showConfirmPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={
                      showConfirmPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                    }
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        color="inherit"
        disabled={formik.isSubmitting}
        sx={{ my: 3 }}
      >
        {formik.isSubmitting ? <CircularProgress size={24} /> : "Sign up"}
      </LoadingButton>
      <Typography
        variant="subtitle2"
        sx={{ display: "flex", justifyContent: "center", opacity: 0.7 }}
      >
        By Signing in you agree to&nbsp;
        <Link href="/terms" target="_blank" sx={{ cursor: "pointer" }}>
          <Typography
            underline="hover"
            sx={{
              display: "inline",
              color: "secondary.main",
            }}
          >
            Terms & Condition
          </Typography>
        </Link>
      </Typography>
    </form>
  );
  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.9),
          imgUrl: "/assets/background/overlay_4.jpg",
        }),
        height: 1,
      }}
    >
      <Logo
        sx={{
          position: "fixed",
          top: { xs: 16, md: 24 },
          left: { xs: 16, md: 24 },
        }}
      />

      <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
        <Card
          sx={{
            p: 5,
            width: 1,
            maxWidth: 420,
          }}
        >
          <Typography variant="h4">Sign up for Riple</Typography>
          <Stack direction={"row"} spacing={0.5}>
            <Typography variant="body2" sx={{ mt: 2, mb: 5 }}>
              Already have an account?
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                ml: 0.5,
                color: "primary.main",
                cursor: "pointer",
                "&:hover": { textDecoration: "underline" },
              }}
              onClick={() => navigate("/login")}
            >
              Sign in
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2}></Stack>

          <Divider sx={{ my: 3 }}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              OR
            </Typography>
          </Divider>

          {renderForm}
        </Card>
      </Stack>
    </Box>
  );
};

export default SignupView;
