import React, { useCallback, useState } from "react";
import { useTheme } from "@emotion/react";
import {
  alpha,
  Box,
  Button,
  Card,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Logo from "src/components/logo";
import { bgGradient } from "src/theme/css";
import { Link, useNavigate } from "react-router-dom";
import Iconify from "src/components/iconify";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import AuthService from "src/services/Auth.service";
import VpnKeyRoundedIcon from "@mui/icons-material/VpnKeyRounded";
const ChangePasswordView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showconfirmPassword, setShowconfirmPassword] = useState(false);
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    otp: Yup.string()
      .required("OTP is required")
      .min(6, "OTP must be 6 digits"),

    //extra some if needed
  });
  const formik = useFormik({
    initialValues: { password: "", confirmPassword: "", email: "", otp: "" },
    validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      onSubmit(values, setSubmitting, resetForm);
    },
  });
  const onSubmit = useCallback(async (formData, setSubmitting, reset) => {
    try {
      setSubmitting(true);
      const res = await AuthService.resetPasswordWithOTP({
        email: formData.email,
        newPassword: formData.password,
        confirmPassword: formData.confirmPassword,
        otp: formData.otp,
      });
      if (res.success) {
        toast.success(res.message);
        navigate("/login");
      } else {
        toast.error(res.message);
      }
      reset();
    } catch (error) {
      toast.error("Error in resetting password:", error);
    } finally {
      setSubmitting(false);
    }
  }, []);
  const renderForm = (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="body1" sx={{ mb: 1 }}>
        Email
      </Typography>
      <TextField
        name="email"
        label="Email address"
        variant="outlined"
        sx={{ width: 1, mb: 3 }}
        value={formik.values.email}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="start">
                <Iconify icon="eva:email-fill" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Typography variant="body1" sx={{ mb: 2 }}>
        Enter OTP
      </Typography>
      <TextField
        name="otp"
        label="Enter OTP"
        variant="outlined"
        sx={{ width: 1, mb: 3 }}
        value={formik.values.otp}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={formik.touched.otp && Boolean(formik.errors.otp)}
        helperText={formik.touched.otp && formik.errors.otp}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <VpnKeyRoundedIcon fontSize="medium" />
            </InputAdornment>
          ),
        }}
      />
      <Typography variant="body1" sx={{ mb: 1 }}>
        New password
      </Typography>
      <TextField
        type={showPassword ? "text" : "password"}
        name="password"
        label="password"
        sx={{ mb: 3, width: 1 }}
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="start"
                onClick={() => setShowPassword((prev) => !prev)}
              >
                <Iconify
                  icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Typography variant="body1" sx={{ mb: 1 }}>
        Confirm new password
      </Typography>
      <TextField
        type={showPassword ? "text" : "password"}
        label="confirm password"
        name="confirmPassword"
        sx={{ mb: 3, width: 1 }}
        value={formik.values.confirmPassword}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          formik.touched.confirmPassword &&
          Boolean(formik.errors.confirmPassword)
        }
        helperText={
          formik.touched.confirmPassword && formik.errors.confirmPassword
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="start"
                onClick={() => setShowconfirmPassword((prev) => !prev)}
              >
                <Iconify
                  icon={
                    showconfirmPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                  }
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Button
        variant="contained"
        color="inherit"
        size="large"
        type="submit"
        fullWidth
        sx={{ py: 1, fontSize: 18 }}
        disabled={formik.isSubmitting}
      >
        Reset Password
      </Button>
    </form>
  );
  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.9),
          imgUrl: "/assets/background/overlay_4.jpg",
        }),
        height: 1,
      }}
    >
      <Logo
        sx={{
          position: "fixed",
          top: { xs: 16, md: 24 },
          left: { xs: 16, md: 24 },
        }}
      />
      <Stack sx={{ height: 1 }} justifyContent="center" alignItems="center">
        <Card sx={{ width: 1, maxWidth: 450, p: 5 }}>
          <Typography variant="h4" textAlign="center">
            Reset Password
          </Typography>

          <Typography
            variant="body2"
            sx={{ opacity: 0.7, mt: 1, mb: 5, textAlign: "center" }}
          >
            Enter your new password to reset your account
          </Typography>
          {renderForm}
          <Typography variant="body2" sx={{ mt: 3, textAlign: "center" }}>
            Remember your password?{" "}
            <Link
              to="/login"
              style={{ color: "white", opacity: 0.6, textDecoration: "none" }}
              onMouseEnter={(e) =>
                (e.target.style.textDecoration = "underline")
              }
              onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
            >
              Sign in
            </Link>
          </Typography>
        </Card>
      </Stack>
    </Box>
  );
};

export default ChangePasswordView;
